import React, { FC, ReactNode } from 'react';
import { Header, Footer } from '@landing/components';

interface MainLayoutProps {
  children?: ReactNode;
  role?: 'client' | 'therapist' | 'business';
}

const MainLayout: FC<MainLayoutProps> = ({ children, role }) => {
  return (
    <div>
      <Header role={role} />
      <main>{children}</main>
      <Footer role={role} />
    </div>
  );
};

export default MainLayout;
