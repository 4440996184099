import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { NextSeo } from 'next-seo';
import {
  ClientHeroSection,
  TherapySection,
  WhyWeSection,
  TeamSection,
  FaqSection,
  GuaranteeSection,
  WriteAboutUs,
} from '@landing/components';
import { ReviewsSection } from '@landing/components/ReviewsSection';
import PartnersSection from '@landing/components/PartnersSection';
import { ITherapist } from '@landing/types/home.page';
import { useRouter } from 'next/router';
import { useClientSize } from '@landing/hooks';

interface IClientProps {
  therapistList: ITherapist[];
  setVideoState: (state: boolean) => void;
}

const Client: FC<IClientProps> = (props) => {
  const { therapistList, setVideoState } = props;

  const reviewsRef = useRef<HTMLDivElement>(null);
  const guaranteeRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { getIsBreakpoint } = useClientSize();

  const isMobile = getIsBreakpoint('s');

  const scrollToReviews = () => {
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const scrollToGuarantee = () => {
    if (guaranteeRef.current) {
      guaranteeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <>
      <NextSeo title="Онлайн-консультации психологов на Поговорим.онлайн — анонимная консультация с психотерапевтом по цене от 2500 ₽" />

      <ClientHeroSection
        therapistList={therapistList}
        scrollToReviews={scrollToReviews}
        scrollToGuarantee={scrollToGuarantee}
      />

      {/* <CatalogButton>

        { isMobile &&

            <StyledButtonLink

            isLarge
            styleType='outline'
            href={API_URL.allTherapists}
            query={router.query}
            target="_blank"
            rel="noopener noreferrer"
            >
              Каталог психологов

            </StyledButtonLink>

        }

      </CatalogButton> */}

      <StyledWhyWeSection setVideoState={setVideoState} />

      <PartnersSection />

      <GuaranteeSection ref={guaranteeRef} />

      <WriteAboutUs />

      <TherapySection />

      <TeamSection />

      <ReviewsSection ref={reviewsRef} />

      {/* <HowItWorksSection /> */}

      <FaqSection />
    </>
  );
};

const StyledWhyWeSection = styled(WhyWeSection)`
  position: relative;
  z-index: 5;
`;

const CatalogButton = styled('div')`
  display: flex;
  justify-content: center;
`;

export default Client;
