import React, { useEffect, useRef, useState } from 'react';
import { MainLayout } from '@landing/layouts';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Client } from '@landing/pageComponents/ClientPage';
import { API_URL } from '@landing/constants';
import type { GetServerSideProps, NextPage } from 'next';
import { ITherapistsWithParamResponse } from '@landing/types/home.page';
import { useClientSize } from '@landing/hooks';
import BasicModal from '@/landing/ui/components/BasicModal';
import apx from '@/landing/constants/adaptivepx';
import styled from 'styled-components';
import { BreakpointsEnum } from '@/landing/types';

interface IHomePageProps {
  therapistsWithParam: ITherapistsWithParamResponse;
}

const HomePage: NextPage<IHomePageProps> = ({ therapistsWithParam }) => {
  const [videoIsOpen, setVideoIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element>();

  const videoRef = useRef<HTMLVideoElement>(null);

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('s');

  const videoUrl = isMobile
    ? '/assets/videos/whyWe.mp4'
    : '/assets/videos/whyWe_desk.mp4';

  const videoPoster = isMobile
  ? '/assets/images/main-preview.jpg'
  : '';

  useEffect(() => {

    setModalContent(

      <ModalVideo
        ref={videoRef}
        controls
        src={videoUrl}
        onEnded={() => setVideoIsOpen(false)}
        poster={videoPoster}
      />

    );

    if (!videoIsOpen && videoRef.current) {

      videoRef.current.pause();
      videoRef.current.currentTime = 0;

    }

  }, [videoUrl, videoIsOpen]);

  return (
    <>
      <BasicModal

        backgroundColor="transparent"
        visible = { videoIsOpen }
        setVisible = { setVideoIsOpen }
        padding = {`${ apx.d( 40 ) }`}
        mobilePadding = {`${ apx.m( 40 ) }`}

      >

        <ModalContent>

          { modalContent }

        </ModalContent>

      </BasicModal>

      <MainLayout role="client">
        <ParallaxProvider>
          <Client
            setVideoState={setVideoIsOpen}
            therapistList={therapistsWithParam.data}
          />
        </ParallaxProvider>
      </MainLayout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<
  IHomePageProps
> = async () => {
  const res = await fetch(API_URL.getTherapistsWithParams);
  const therapistsWithParam: ITherapistsWithParamResponse = await res.json();

  return {
    props: { therapistsWithParam },
  };
};

export default HomePage;

const ModalContent = styled.div`
  width: ${ apx.d( 1000 ) };

  @media screen and ( max-width: ${BreakpointsEnum.md}px) {
    width: ${ apx.m( 180 ) };
  }
`;

const ModalVideo = styled.video`
  border-radius: ${ apx.d( 30 ) };

  @media screen and ( max-width: ${BreakpointsEnum.md}px) {
    border-radius: ${ apx.m( 10 ) };
  }

  @media screen and ( max-width: ${BreakpointsEnum.s}px) {
    border-radius: ${ apx.m( 20 ) };
  }
`;
