import { CloseIcon } from '@landing/assets/svgComponents/socialMedia/exit';
import styled from 'styled-components';
import { BreakpointsEnum } from '@landing/types';
import apx from '@landing/constants/adaptivepx';

interface BasicModalContainerProps {

  active?: boolean;

}

interface BasicModalCloseBtn {

  onClick?: () => void;
  widthSvg?: string;
  heightSvg?: string;
  mobileWidthSvg?: string;
  mobileHeightSvg?: string;
  positionSvgRight?: string;
  positionSvgTop?: string;
  positionMobileSvgRight?: string;
  positionMobileSvgTop?: string;
  isCloseBtnFixed?: boolean;

}

interface BasicModalContentProps {

  backgroundColor?: string;
  padding?: string;
  mobilePadding?: string;
  margin?: string | null;
  mobileRadius?: string | null;
  mobileWidth?: string | null;
  mobileHeight?: string | null;
  mobilePosition?: string | null;

}

const BasicModalContainer = styled.div<BasicModalContainerProps>`

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  z-index: 102;

  ${({ active }) =>
    active &&
    `
    display: flex;
    justify-content: center;
    align-items: center;

  `}
  
`;


const BasicModalContent = styled.div<BasicModalContentProps>`

  position: relative;
  padding: ${apx.d(25)};
  background-color: ${props => props.backgroundColor || '#EEF9F9'};
  border-radius: ${apx.d(20)};
  padding: ${props => props.padding || `${apx.d(37)} ${apx.d(241)} ${apx.d(40)} ${apx.d(34)}`};
  font-size: ${apx.d(26)};
  font-weight: 700;
  line-height: 100%;
  color: #172E2C;
  background-image: url('/assets/images/sheet-big.svg');
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: top -${apx.d(4)} right ${apx.d(62)};
  user-select: none;
  overflow-y: auto;
  max-height: 90vh;

  @media (max-width: ${BreakpointsEnum.md}px) {

    padding: ${props => props.mobilePadding || `${ apx.m( 24 ) } ${ apx.m( 60 ) }`};
    border-radius: ${apx.m(20)};
    margin-right: ${apx.m(20)};
    margin-left: ${apx.m(20)};
    height: ${props => props.mobileHeight || 'auto'};
    font-size: ${apx.m(24)};
    overflow-wrap: break-word;
    text-align: center;
    background-image: url('/assets/images/sheet-small.svg');
    background-size: 24%;
    background-repeat: no-repeat;
    background-position: top ${apx.m(10)} left ${apx.m(8)};

  }

  @media (max-width: ${BreakpointsEnum.sm}px) {

    border-radius: ${props => props.mobileRadius || `${apx.m(20)}`};
    margin-right: ${props => props.margin || `${apx.m(20)}`};
    margin-left: ${props => props.margin || `${apx.m(20)}`};
    width: ${props => props.mobileWidth || 'auto'};
    height: ${props => props.mobileHeight || 'auto'};
    position: ${props => props.mobilePosition || 'relative'};

    top: 0;
    z-index: 100;
    margin-top: ${props => props.margin || `${apx.m(200)}`};
    margin-bottom: ${props => props.margin || `${apx.m(200)}`};

  }

`;

const BasicModalCloseBtn = styled(CloseIcon)<BasicModalCloseBtn>`

  position: absolute;
  width: ${({ widthSvg }) => widthSvg || apx.d(40)}; 
  height: ${({ heightSvg }) => heightSvg || apx.d(40)};
  right: ${({ positionSvgRight }) => positionSvgRight || apx.d(6)}; 
  top: ${({ positionSvgTop }) => positionSvgTop || apx.d(6)};
  cursor: pointer;

  @media (max-width: ${BreakpointsEnum.md}px) {

    position: ${({ isCloseBtnFixed }) => (isCloseBtnFixed ? 'fixed' : 'absolute')};
    width: ${({ mobileWidthSvg }) => (mobileWidthSvg ? apx.m(parseInt(mobileWidthSvg)) : apx.m(34))};
    height: ${({ mobileHeightSvg }) => (mobileHeightSvg ? apx.m(parseInt(mobileHeightSvg)) : apx.m(34))};
    /* right: ${({ positionMobileSvgRight, isCloseBtnFixed }) => positionMobileSvgRight || isCloseBtnFixed ? `${apx.m(24)}` : `${apx.d(24)}`};  */
    /* top: ${({ positionMobileSvgTop, isCloseBtnFixed }) => positionMobileSvgTop || isCloseBtnFixed ? `${apx.m(24)}` : `${apx.d(24)}`}; */

    top: ${({ positionMobileSvgTop }) => positionMobileSvgTop || apx.m(24)};
    right: ${({ positionMobileSvgRight }) => positionMobileSvgRight || apx.m(24)}; 

  }

`;

export { BasicModalContainer, BasicModalContent, BasicModalCloseBtn };