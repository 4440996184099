export type ReviewProps = {
  name: string;
  review: string;
  date: string;
  rating: number;
};

export const reviews: ReviewProps[] = [
  {
    name: 'Алексей',
    review: 'Максимально комфортно прошла первая сессия',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Стало лучше, спасибо большое',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Понравилась сессия, буду продолжать занятия с психотерапевтом',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Почувствовал эмоции, что раньше с трудом происходило',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Благодарен, были затронуты важные темы',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Сессия понравилась. Продолжем работать.',
    date: '23 декабря 2023',
    rating: 5,
  },

  {
    name: 'Алексей',
    review: 'Спасибо, было полезно. Обсудили важную для меня тему.',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Терапевт создал доверительную атмосферу, бережное пространство',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'У сессии очень доверительная атмосфера',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Всё прошло хорошо, всё понравилось. Благодарю.',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review:
      'Все прошло отлично. через пробы мы пришли к тому, что будем заниматься дальше.',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Потрясающе! Осталась очень довольна консультацией. Пойду ещё))',
    date: '23 декабря 2023',
    rating: 5,
  },
  {
    name: 'Алексей',
    review: 'Сеанс прошел хорошо. Специалист подсветил мои точки роста.',
    date: '23 декабря 2023',
    rating: 5,
  },
];
