import 'swiper/css';
import 'swiper/css/free-mode';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import styled from 'styled-components';
import { reviews } from './reviewsData';
import { ReviewCard } from './components';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { BreakpointsEnum } from '@landing/types';
import Container from '../Container/Container';
import SliderNextArrow from '@landing/ui/iconComponents/SliderNextArrow';
import apx from '@landing/constants/adaptivepx';
import { forwardRef, HTMLAttributes, ReactElement, ReactNode } from 'react';

type DivAttributesWithOmittedTitle = Omit<
  HTMLAttributes<HTMLDivElement>,
  'title'
>;

interface ReviewsSectionProps extends DivAttributesWithOmittedTitle {
  title?: ReactElement;
  className?: string;
  children?: ReactNode;
}

const ReviewsSection = forwardRef<HTMLDivElement, ReviewsSectionProps>(
  ({ children, title, className }, ref) => (
    <div
      className={className}
      id="reviews"
      ref={ref}
      style={{ scrollMargin: 100 }}
    >
      <Root className="root">
        <StyledContainer className="container">
          {title ? title : <Heading>{'Отзывы клиентов'}</Heading>}

          <HeadingContainer>
            <button className="swiper-button-prev">
              <SliderNextArrow />
            </button>
            <button className="swiper-button-next">
              <SliderNextArrow />
            </button>
          </HeadingContainer>
        </StyledContainer>

        <SwiperRoot
          spaceBetween={20}
          className="slider"
          slidesPerView={'auto'}
          freeMode
          modules={[Navigation, FreeMode]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
        >
          {children
            ? children
            : reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <ReviewCard
                    name={review.name}
                    review={review.review}
                    date={review.date}
                    rating={review.rating}
                  />
                </SwiperSlide>
              ))}
        </SwiperRoot>
      </Root>
    </div>
  ),
);

ReviewsSection.displayName = 'ReviewsSection';

export default ReviewsSection;

const Root = styled.div`
  padding-top: ${apx.d(107)};
  padding-bottom: ${apx.d(88)};
  position: relative;
  overflow: hidden;
  background: #fbfbfb;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    background: transparent;
    padding-bottom: ${apx.l(66)};
    padding-top: ${apx.l(64)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-bottom: ${apx.m(53)};
    padding-top: ${apx.m(50)};
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding-left: ${apx.l(40)};
    padding-right: ${apx.l(40)};
  }
`;

const HeadingContainer = styled.div`
  display: flex;

  column-gap: ${apx.d(4)};
  z-index: 10;

  .swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${apx.d(36)};
    height: ${apx.d(36)};
    border-radius: 50%;
    cursor: pointer;
    background: ${COLORS.white};

    svg {
      transform: rotate(180deg);
      width: ${apx.d(15)};
      height: ${apx.d(10)};
    }

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${apx.d(36)};
    height: ${apx.d(36)};
    border-radius: 50%;
    cursor: pointer;
    background: ${COLORS.white};

    svg {
      width: ${apx.d(15)};
      height: ${apx.d(10)};
    }

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    display: none;
  }
`;

const Heading = styled.h2`
  margin-bottom: ${apx.d(66)};
  ${TYPOGRAPHY.heading2SemiBold64};
  font-size: ${apx.d(48)} !important;
  line-height: ${apx.d(24)} !important;
  color: ${COLORS.nativeBlack};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(50)};
    font-size: ${apx.l(30)} !important;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(30)} !important;
    font-weight: 700;
    margin-bottom: ${apx.m(39)} !important;

    margin: 0 auto;
    line-height: ${apx.m(24)};
  }
`;

const SwiperRoot = styled(Swiper)`
  overflow: unset;
  padding-left: 12.6vw;
  padding-right: 12.6vw;

  .swiper-slide {
    width: ${apx.d(465)};
    display: flex;
    height: auto;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding-left: ${apx.l(30)};
    padding-right: ${apx.l(30)};

    .swiper-slide {
      width: ${apx.l(349)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-left: ${apx.m(25)};
    padding-right: ${apx.m(25)};

    .swiper-slide {
      width: ${apx.m(261)};
    }
  }
`;
