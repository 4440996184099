import React, { FC, ReactNode, useEffect } from 'react';
import {
  BasicModalCloseBtn,
  BasicModalContainer,
  BasicModalContent,
} from './BasicModal.styled';

interface Props {

  children: ReactNode;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  backgroundColor?: string;
  padding?: string;
  mobilePadding?: string;
  margin?: string | null;
  mobileRadius?: string | null;
  mobileWidth?: string | null;
  mobileHeight?: string | null;
  mobilePosition?: string | null;
  widthSvg?: string
  heightSvg?: string;
  mobileWidthSvg?: string;
  mobileHeightSvg?: string;
  positionSvgRight?: string;
  positionSvgTop?: string;
  positionMobileSvgRight?: string;
  positionMobileSvgTop?: string;
  isCloseBtnFixed?: boolean;
  
}

const BasicModal: FC<Props> = ({ 

  children, 
  backgroundColor,
  padding,
  mobilePadding, 
  margin, mobileRadius,
  mobileWidth, 
  mobileHeight, 
  mobilePosition,
  visible, 
  widthSvg,
  heightSvg,
  mobileWidthSvg,
  mobileHeightSvg, 
  setVisible, 
  positionSvgRight,
  positionSvgTop,
  positionMobileSvgRight,
  positionMobileSvgTop,
  isCloseBtnFixed

}) => {
  
  useEffect(() => {

    if ( visible ) {

      document.body.style.overflow = 'hidden';

    } else {

      document.body.style.overflow = 'unset';

    }

    return () => {

      document.body.style.overflow = 'unset';

    };

  }, [ visible ]);

  return (

    <BasicModalContainer active = { visible }>

      <BasicModalContent

        backgroundColor = { backgroundColor }
        onClick = { (e: React.MouseEvent) => e.stopPropagation() }
        padding = { padding }
        mobilePadding = { mobilePadding }
        margin = { margin }
        mobileRadius = { mobileRadius }
        mobileWidth = { mobileWidth }
        mobileHeight = { mobileHeight }
        mobilePosition = { mobilePosition }

       >

        { children }

        <BasicModalCloseBtn 

          widthSvg = { widthSvg }
          heightSvg = { heightSvg }
          mobileWidthSvg = { mobileWidthSvg }
          mobileHeightSvg = { mobileHeightSvg } 
          onClick = { () => setVisible(false) } 
          positionSvgRight={positionSvgRight}
          positionSvgTop={positionSvgTop}
          positionMobileSvgRight={positionMobileSvgRight}
          positionMobileSvgTop={positionMobileSvgTop}
          isCloseBtnFixed = { isCloseBtnFixed }

        />

      </BasicModalContent>

    </BasicModalContainer>

  );
};

export default BasicModal;
