import { COLORS, TYPOGRAPHY } from '@landing/constants';
import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useClientSize, useToggle } from '@landing/hooks';
import { BreakpointsEnum } from '@landing/types';
import { Rating } from '@landing/ui/components';
import apx from '@landing/constants/adaptivepx';

type ReviewCardProps = {
  name: string;
  date: string;
  review: string;
  rating: number;
};

const ReviewCard: FC<ReviewCardProps> = ({ name, review, rating }) => {
  const { state: isOpened, toggle } = useToggle();
  const [isResize, setResize] = useState(false);
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('s');

  const hasShowMore = name === 'Алексей' ? false : true;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setResize(true);
      } else {
        setResize(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Root>
      <Author>
        <RatingBox>
          <Rating rating={rating} />
        </RatingBox>
      </Author>
      <Review
        $isGradientText={isOpened ? false : hasShowMore}
        $hasPointer={hasShowMore}
        $isResize={isResize}
        onClick={toggle}
      >
        {hasShowMore && !isOpened && !isResize && !isMobile
          ? review.slice(0, 425) + '...'
          : review}
      </Review>
      {/* <ReviewBottomWrapper hasShowMore = { hasShowMore }>
        <UserInfo>{name} от {date}</UserInfo>
        {hasShowMore && !isResize && (
        <ShowMore $isOpened={isOpened} onClick={toggle}>
          Читать полностью ↓
        </ShowMore>
      )}
      </ReviewBottomWrapper> */}
    </Root>
  );
};

export default ReviewCard;

const Root = styled.div`
  background-color: ${COLORS.white};

  width: 100%;
  height: 100% !important;

  padding: ${apx.d(43)} ${apx.d(24)} ${apx.d(30)} ${apx.d(44)};
  border-radius: ${apx.d(40)};
  box-shadow: 0 0 ${apx.d(50)} 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  /* min-height: 468px; */
  z-index: 100;
  justify-content: flex-start !important;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    justify-content: flex-end;
    padding: ${apx.l(32)};
    border-radius: ${apx.l(27)};
    box-shadow: 0 0 ${apx.l(50)} 0 rgba(0, 0, 0, 0.05);
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: ${apx.m(28)} ${apx.m(27)} ${apx.m(32)} ${apx.m(23)};
    border-radius: ${apx.m(27)};
    box-shadow: 0 0 ${apx.m(50)} 0 rgba(0, 0, 0, 0.05);
  }
`;

const RatingBox = styled.div``;

// const ReviewBottomWrapper = styled.div<{ hasShowMore: boolean }>`

//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 31px;

//   @media (max-width: ${BreakpointsEnum.sm}px) {

//     margin-top: ${({ hasShowMore }) => hasShowMore ? '27px' : '17px'};

//   }

//   @media (max-width: ${BreakpointsEnum.s}px) {

//     margin-top: 18px !important;

//   }

// `;

// const UserInfo = styled.div`
//   ${TYPOGRAPHY.bodyRegular14};
//   font-size: 15px;
//   margin-left: auto;
//   color: ${COLORS.gray};

//   @media (max-width: ${BreakpointsEnum.sm}px) {

//     font-size: 11px;

//   }

//   @media (max-width: ${BreakpointsEnum.s}px) {

//     text-align: left !important;
//     font-size: 12px !important;
//     margin-right: auto;
//     margin-left: 0;

//   }

// `

const Author = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: 20px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    grid-template-columns: 80px 1fr;
    column-gap: 18px;
  }
`;

// const Info = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
//   margin-bottom: 40px;
// `;

const Review = styled.p<{
  $isGradientText: boolean;
  $hasPointer: boolean;
  $isResize: boolean;
}>`
  ${TYPOGRAPHY.bodyMedium18}
  line-height: ${apx.d(30)};
  height: 100%;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(13)} !important;
    line-height: 165%;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(12)} !important;
    line-height: 165%;
  }

  ${({ $hasPointer }) => {
    if ($hasPointer)
      return css`
        cursor: pointer;
      `;
  }}

  color: #000;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    height: 100%;
  }
`;

// const Img = styled.img`
//   border-radius: 50%;
//   width: 100px;
//   height: 100px;
//   object-fit: cover;

//   @media (max-width: ${BreakpointsEnum.sm}px) {
//     width: 80px;
//     height: 80px;
//   }
// `;

// const ShowMore = styled.div<{ $isOpened: boolean }>`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   ${TYPOGRAPHY.bodySemibold162}
//   font-size: 15px;
//   cursor: pointer;
//   order: -1;
//   color: ${COLORS.green};
//   text-decoration: underline;

//   svg {
//     transform: rotate(90deg);
//     scale: 1.8;
//     path {
//       fill: ${COLORS.white};
//     }
//   }

//   ${({ $isOpened }) => {
//     if ($isOpened)
//       return css`
//         svg {
//           transform: rotate(-90deg);
//         }
//       `;
//   }}

//   @media (max-width: ${BreakpointsEnum.sm}px) {

//     font-size: 11px;

//   }

//   @media (max-width: ${BreakpointsEnum.s}px) {

//     font-size: 11px;
//     display: none;

//   }

// `;

// const AuthorImage = styled.div`
//   display: flex;
//   align-items: center;
//   max-height: 127px;
// `;
